<template>
  <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-form-model-item label="起止日期">
      <!-- v-model="form.cdExpectStartTime"  :value="moment(1721183257000)"-->
      <a-date-picker
        v-model="StartDate"
        :disabled-date="disabledStartDate"
        format="YYYY-MM-DD"
        placeholder="起始日期"
        @openChange="handleStartOpenChange"
        @change="onChangeDateStart"
      />
      <a-date-picker
        v-model="EndDate"
        :disabled-date="disabledEndDate"
        format="YYYY-MM-DD"
        placeholder="截止日期"
        :open="endOpen"
        @openChange="handleEndOpenChange"
        @change="onChangeDateEnd"
      />
    </a-form-model-item>
    <a-form-model-item label="施工人数">
      <a-input
        v-model="form.cdPeople"
        placeholder="请输入施工人数"
        class="cdPeople"
      />
    </a-form-model-item>
    <div class="title title1">验收(选填)</div>
    <a-form-model-item label="验收方式">
      <a-select v-model="form.cdCheckWay" placeholder="请选择验收方式">
        <a-select-option
          v-for="opt in wpCheckWay"
          :key="opt.CheckwayId"
          :value="opt.CheckwayId"
          >{{ opt.CheckwayName }}</a-select-option
        >
      </a-select>
    </a-form-model-item>

    <div
      class="displayflex"
      v-for="(domain, index) in dynamicValidateForm.domains"
      :key="index"
      v-bind="index === 0 ? formItemLayout : {}"
    >
      <!-- label="选择并且或或者" -->
      <div class="isOrAndSelect" v-if="index > 0">
        <a-form-model-item prop="cdCheckList">
          <a-select v-model="domain.isOrAnd" class="search-expand-value">
            <a-select-option
              v-for="opt in isOrAnd"
              :key="opt.key"
              :value="opt.key"
              >{{ opt.value }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </div>

      <a-form-model-item
        prop="cdCheckList"
        label="验收人岗位"
        :required="false"
      >
        <a-select
          mode="multiple"
          v-model="domain.post"
          class="search-expand-value"
        >
          <a-select-option
            v-for="opt in wpCheckPost"
            :key="opt.CheckPostId"
            :value="opt.CheckPostId"
            >{{ opt.CheckPostName }}</a-select-option
          >
        </a-select>
        <a-icon
          v-if="dynamicValidateForm.domains.length > 1"
          class="dynamic-delete-button"
          type="minus-circle-o"
          :disabled="dynamicValidateForm.domains.length === 1"
          @click="removeDomain(domain)"
        />
      </a-form-model-item>
    </div>
    <div>
      <a-form-item v-bind="formItemLayoutWithOutLabel">
        <a-button type="dashed" style="width: 60%" @click="addDomain">
          <a-icon type="plus" /> 添加验收岗位
        </a-button>
      </a-form-item>
    </div>

    <a-form-model-item label="是否需要业主验收">
      <a-radio-group v-model="form.cdCheckOwner">
        <a-radio value="Y"> 是 </a-radio>
        <a-radio value="N"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <!-- <a-button type="primary" @click="onSubmit"> 保存 </a-button> -->
      <a-button type="primary" @click="onSubmit('dynamicValidateForm')">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="onClose"> 取消 </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import moment from "moment";
import { GetProjectList } from "@/api/apiJF/template";
export default {
  name: "ScheduleEdit",
  props: {
    record: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      moment,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        cdBcId: "",
        cdExpectStartTime: "",
        cdExpectEndTime: "",
        cdPeople: "",
        cdCheckWay: "",
        cdCheckOwner: "",
        cdCheckList: "",
      },
      StartDate: "",
      EndDate: "",
      endOpen: false,
      //   验收方式
      wpCheckWay: [
        { CheckwayId: 1, CheckwayName: "远程验收" },
        { CheckwayId: 2, CheckwayName: "现场验收" },
        { CheckwayId: 3, CheckwayName: "远程+现场验收" },
        { CheckwayId: 4, CheckwayName: "远程/现场验收" },
      ],
      //   验收岗位
      wpCheckPost: [
        { CheckPostId: "1", CheckPostName: "工程管家" },
        { CheckPostId: "2", CheckPostName: "项目经理" },
      ],
      cdCheckList: [
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
        { post: [], isOrAnd: "" },
      ],
      isOrAnd: [
        { key: "and", value: "且" },
        { key: "or", value: "或" },
      ],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      dynamicValidateForm: {
        domains: [],
      },
      editForm: {},
      ProjectList: [], //施工项列表
    };
  },
  created() {
    // console.log("create---record", this.record, this.type);
    if (this.type == "edit") {
      for (let el in this.record) {
        this.form[el] = this.record[el];
      }
    }
    // this.form.cdExpectStartTime = Date.parse(new Date()) / 1000;
    // this.form.cdExpectEndTime = Date.parse(new Date()) / 1000;
    this.StartDate = this.form.cdExpectStartTime
      ? this.$Format(this.form.cdExpectStartTime)
      : "";
    this.EndDate = this.form.cdExpectEndTime
      ? this.$Format(this.form.cdExpectEndTime)
      : "";
    // console.log("this.form", this.form);
    let cdCheckList = this.form.cdCheckList
      ? JSON.parse(this.form.cdCheckList)
      : [];
    // cdCheckList = [
    //   {
    //     post: ["1"],
    //     isOrAnd: "",
    //   },
    //   {
    //     post: ["2"],
    //     isOrAnd: "or",
    //   },
    // ];
    // console.log("cdCheckList", cdCheckList);
    let cdCheckListNew = [];
    cdCheckList.forEach((el) => {
      cdCheckListNew.push({
        post: el.post.split(","),
        isOrAnd: el.isOrAnd ? el.isOrAnd : "",
      });
      // console.log("cdCheckListNew", el.post.split(","));
    });
    // this.dynamicValidateForm.domains = cdCheckList ? cdCheckList : [];
    this.dynamicValidateForm.domains = cdCheckListNew ? cdCheckListNew : [];
    // 获取施工项
    this.GetProjectList(this.form.cdBcId);
  },
  methods: {
    onSubmit() {
      let NewWpCheckPost = [];
      this.dynamicValidateForm.domains.forEach((el) => {
        if (el.post != "" && el.post) {
          //   console.log("el.post", el.post);
          NewWpCheckPost.push({
            post: el.post.toString(),
            isOrAnd: el.isOrAnd ? el.isOrAnd : "",
          });
        }
      });
      this.form.cdCheckList = JSON.stringify(NewWpCheckPost);
      // console.log("submit!", this.form);
      let children = [];
      children.push(this.form);
      this.editForm = {
        cpWpId: this.form.cdWpId,
        children: children,
      };
      //   console.log("editForm", this.editForm);
      this.$emit("tabledataChange", this.editForm);
    },
    // 获取施工项
    GetProjectList(pBcIds) {
      let queryParam = {
        pBcIds: pBcIds ? pBcIds : this.CompanyListid.toString(),
      };
      let orderParam = ["pBcIds"];
      const param = Object.assign({}, queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetProjectList(params).then((res) => {
        this.ProjectList = res.data;
        res.data.forEach((el) => {
          if (this.form.cdPId == el.pid) {
            // console.log("施工项", el);
            this.form.cdDuration =
              (this.form.cdQuantity * el.pbuildTime) / el.pmaxBuildTime;
          }
        });
        // console.log("施工项", res.data);
      });
    },

    // 起止日期的选择和禁止选择
    disabledStartDate(startValue) {
      const endValue = this.EndDate;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.StartDate;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    // 关闭
    onClose() {
      this.$emit("onClose");
    },
    // 验收岗位
    removeDomain(item) {
      let index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      //   console.log("addDomain", this.dynamicValidateForm.domains);
      this.dynamicValidateForm.domains.push({
        value: "",
        key: Date.now(),
      });
    },
    onChangeDateStart(date, dateString) {
      let start = Date.parse(date) / 1000;
      this.form.cdExpectStartTime = start;
    },
    onChangeDateEnd(date, dateString) {
      let end = Date.parse(date) / 1000;
      this.form.cdExpectEndTime = end;
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
  margin: 20px 0;
  font-weight: 800;
  font-size: 16px;
}

.title1 {
  margin-top: 50px;
}
/deep/.ant-row {
  display: flex;
  width: 79%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}

.displayflex {
  display: flex;
  // justify-content: left;
  // width: 100%;

  /deep/ .ant-form-item-control-wrapper {
    width: 80%;
  }
  /deep/.ant-form-item-label {
    width: 200px;
  }
  /deep/.isOrAndSelect {
    width: 90px;
    margin-right: -110px;
    margin-left: 20px;
    z-index: 11;
  }
  /deep/.ant-form-item-children {
    display: flex;
  }
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.cdPeople {
  width: 230px;
}
</style>
